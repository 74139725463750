.op-quick-add-menu
  @include breakpoint(680px down)
    display: none

  &--icon::before
    display: flex
    justify-content: center
    align-items: center
    border: 2px solid var(--header-item-font-color)
    border-radius: 50%
    width: 26px
    height: 26px
    padding: 1px 8px 0 8px
    background: var(--button--alt-highlight-background-color)
    border-color: var(--button--alt-highlight-background-color)

  &:hover &
    &--icon::before
      background: var(--button--alt-highlight-background-hover-color)
      border-color: var(--button--alt-highlight-background-hover-color)
